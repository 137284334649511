import React, { useEffect, useState } from 'react';
import { Fade, Form, Button, Container, Row, Col, Spinner } from 'reactstrap'
import { Link } from "react-router-dom";
import Smile from './icons/subscribe.png'
import { getHomeState } from '../../features/homepageSlice';
import { getBootState } from '../../features/bootstrapSlice'
import { useSelector } from 'react-redux'
import Switch from 'react-ios-switch'

function Subscribe(props) {

    const homeData = useSelector(getHomeState);
    const bootData = useSelector(getBootState);
    
    // console.log(bootData);

    //console.log(props.match.params.email);

    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [nazionalita, setNazionalita] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState(props.match.params.email);
    const [res, setRes] = useState(false);
    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
    const [isCheckedMarketing, setIsCheckedMarketing] = useState(false);
    const [isCheckedMarketingThird, setIsCheckedMarketingThird] = useState(false);
    const [isCheckedProfiling, setIsCheckedProfiling] = useState(false);
    var profilazione = 0;
    var marketing = 0;
    var marketingthird = 0;

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (bootData.posto_master.custom[6]==='1') {
            profilazione = isCheckedProfiling ? 1 : 0;
        }else {
            setIsCheckedProfiling(true);
            profilazione = 0;
        }
        if (bootData.posto_master.custom[7]==='1') {
            marketing = isCheckedMarketing ? 1 : 0;
        }else {
            setIsCheckedMarketing(true);
            marketing = 0;
        }
        if (bootData.posto_master.custom[8]==='1') {
            marketingthird = isCheckedMarketingThird ? 1 : 0;
        }else {
            setIsCheckedMarketingThird(true);
            marketingthird = 0;
        }
    }, [bootData])

    const master = props.match.params.master;

    const submitForm = async (e) => {
        e.preventDefault()
        //da rivedere
        if (bootData.posto_master.custom[6]==='1') {
            profilazione = isCheckedProfiling ? 1 : 0;
        }else {
            setIsCheckedProfiling(true);
            profilazione = 0;
        }
        if (bootData.posto_master.custom[7]==='1') {
            marketing = isCheckedMarketing ? 1 : 0;
        }else {
            setIsCheckedMarketing(true);
            marketing = 0;
        }
        if (bootData.posto_master.custom[8]==='1') {
            marketingthird = isCheckedMarketingThird ? 1 : 0;
        }else {
            setIsCheckedMarketingThird(true);
            marketingthird = 0;
        }

        console.log(email, isCheckedMarketing, isCheckedPrivacy, isCheckedProfiling, isCheckedMarketingThird)

        if(email !== '' && isCheckedMarketing && isCheckedPrivacy && isCheckedProfiling && isCheckedMarketingThird){
            
            setLoading(true)

            try {
                await fetch('/auto_act.php?action=subscribe&master='+master+'&email='+email+'&nome='+nome+'&cognome='+cognome+'&nazionalita='+nazionalita+'&numerotel='+telefono+'&profilazione='+profilazione+'&marketing='+marketing+'&marketingthird='+marketingthird, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        mode: 'cors'
                    },
                    body: ""
                })

                setLoading(false)
                setRes(true);

            }catch(error) {
                setRes(true);
                setLoading(false)
            }

        }else {
            alert('Compila i campi obbligatori')
        }
        
    }
    return (
        <Fade className="text-center">
            {res 
            ?
                <img src={Smile} alt="" style={{height: '40vh'}}/>
            :
            <>
            <Form onSubmit={submitForm}>
                <Container>
                    <span className="text-center d-block">{homeData.testo172}</span>
                    {bootData.posto_master.custom[1]==='1' &&
                        <input onChange={(e) => setNome(e.target.value)} className="input-service my-2 nome" placeholder={homeData.testo24} style={{ borderColor: "#f5f5f5" }} required />}
                    {bootData.posto_master.custom[2]==='1' &&
                        <input onChange={(e) => setCognome(e.target.value)} className="input-service my-2 cognome" placeholder={homeData.testo15} style={{ borderColor: "#f5f5f5" }} required />}
                    {bootData.posto_master.custom[3]==='1' &&
                        <input onChange={(e) => setTelefono(e.target.value)} className="input-service my-2 telefono" placeholder={"Telefono"} style={{ borderColor: "#f5f5f5" }} required />}
                    <input onChange={(e) => setEmail(e.target.value)} defaultValue={props.match.params.email} className="input-service my-2 email" placeholder="Email" style={{ borderColor: "#f5f5f5" }} required />
                    {bootData.posto_master.custom[4]==='1' &&
                        <input onChange={(e) => setNazionalita(e.target.value)} list="nazionalita" className="input-service" placeholder={homeData.testo123}/>
                    }
                    <datalist id="nazionalita">
                        {bootData.nazioni && bootData.nazioni.map((nazione,index) => 
                            <option key={index} value={nazione.id}>{nazione.nome}</option>
                        )}
                    </datalist>
                    {bootData.posto_master.custom[6]==='1' &&
                        <Row className="mt-3">
                            <Col className="d-flex align-items-center">
                                <Link to={'/'+master+'/profiling.html'} className='text-link'>Profiling Policy</Link>
                            </Col>
                            <Col className='col-auto'>
                                <Switch
                                    checked={isCheckedProfiling}
                                    onChange={() => { setIsCheckedProfiling(!isCheckedProfiling) }}
                                    className="d-flex float-right"
                                    style={{ transform: "scale(0.8)" }}
                                    offColor="#37363b"
                                />
                            </Col>
                        </Row>
                    }
                    {bootData.posto_master.custom[7]==='1' &&
                        <Row className="mt-3">
                            <Col className="d-flex align-items-center">
                                <Link to={'/'+master+'/marketing.html'} className='text-link'>Marketing Policy</Link>
                            </Col>
                            <Col className='col-auto'>
                                <Switch
                                    checked={isCheckedMarketing}
                                    onChange={() => { setIsCheckedMarketing(!isCheckedMarketing) }}
                                    className="d-flex float-right"
                                    style={{ transform: "scale(0.8)" }}
                                    offColor="#37363b"
                                />
                            </Col>
                        </Row>
                    }
                    {bootData.posto_master.custom[8]==='1' &&
                        <Row className="mt-3">
                            <Col className="d-flex align-items-center">
                                <Link to={'/'+master+'/marketingthird.html'} className='text-link'>Marketing Third Parties Policy</Link>
                            </Col>
                            <Col className='col-auto'>
                                <Switch
                                    checked={isCheckedMarketingThird}
                                    onChange={() => { setIsCheckedMarketingThird(!isCheckedMarketingThird) }}
                                    className="d-flex float-right"
                                    style={{ transform: "scale(0.8)" }}
                                    offColor="#37363b"
                                />
                            </Col>
                        </Row>
                    }
                    <Row className="mt-3">
                        <Col className="d-flex align-items-center">
                            <Link to={'/'+master+'/privacy.html'} className='text-link'>Privacy Policy</Link>
                        </Col>
                        <Col className='col-auto'>
                            <Switch
                                checked={isCheckedPrivacy}
                                onChange={() => { setIsCheckedPrivacy(!isCheckedPrivacy) }}
                                className="d-flex float-right"
                                style={{ transform: "scale(0.8)" }}
                                offColor="#37363b"
                                required
                            />
                        </Col>
                    </Row>
                    {!loading
                    ?
                        <Button type="submit" className="my-3 w-100 font-weight-bold" style={{backgroundColor:bootData.posto_master.custom[5]}}>{homeData.testo159}</Button>
                    :
                        <Button type="submit" className="my-3 w-100 font-weight-bold">
                            <Spinner style={{ width: '1rem', height: '1rem' }} children={false} />                                
                        </Button>
                    }
                </Container>
            </Form>
            </>
            }
        </Fade>
    )
}

export default Subscribe